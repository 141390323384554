.folder{
    display: inline-block;
    padding: 10px;
    border: 1px solid rgb(218 220 224);
    width: 200px;
    border-radius: 6px;
    margin: 10px 10px 10px 0;
    cursor: pointer;
    font-weight: 500;
    user-select: none;
    background: #9fc5ea;
}
.folder:hover{
    background: #e7e7e7;
}

.folder p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 5px;
    display: inline-block;
    vertical-align: middle;
}
.folder span{
    vertical-align: middle;
}

.folderIcon{
    margin-right: 10px;
    font-size: 25px;
    vertical-align: sub;
    
}

.file{
    display: inline-block;
    padding: 10px;
    border: 1px solid rgb(218 220 224);
    width: 230px;
    border-radius: 6px;
    margin: 10px 10px 10px 0;
    cursor: pointer;
    font-weight: 500;
    user-select: none;
    text-align: center;
    background: white;
}

.mask{
    background-color: black;
}

.file img {
    object-fit: cover;
    width: 210px;
}

.file:hover{
    background: #e7e7e7;
}
.file p {
    margin-bottom: 0;
    margin-top: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 5px;
}

.fileIcon{
    margin-right: 10px;
    font-size: 100px;
    font-weight: normal;
    vertical-align: sub;
    padding-top: 50px;
    padding-bottom: 50px;
}

.imageSkeleton{
    padding: 23px 0;
}

.imageSkeleton div {
    width: 130px !important;
    height: 154px !important;
}

/* Mobile styles */

.file-mobile{
    width: 150px;
    padding: 3px;
}

.file-mobile img {
    width: 140px;
    height: 120px;
}

.file-mobile div > .ant-image-mask {
    opacity: 1;
}

.folder-mobile{
    width: 120px;
    padding: 3px;
}

.folder-mobile img {
    width: 114px;
    height: 120px;
}

.folder-mobile span {
    width: 114px;
    object-fit: cover;
    padding-top: 15px;
}

.imageSkeleton-mobile div {
    width: 100px !important;
    height: 90px !important;
}